
import { BrowserRouter, Routes, Route,Navigate } from "react-router-dom";
import Pub from "../main/application/main";
import Login from "../main/portal/login";
import Main from "../main/portal/dashboard";
import Details from "../main/portal/detail";
import Prove from "../main/portal/prove";

export default function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Pub />}/>
        <Route index element={<Pub />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/main" element={<Main />} />
        <Route exact path="/details" element={<Details />} />
        <Route exact path="/pro" element={<Prove />} />
        <Route path="*" element={<Navigate to="/" replace={true} />}
    />
      </Routes>
    </BrowserRouter>
  );
}
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import AddIcon from '@mui/icons-material/ControlPointSharp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import P1 from './p1';
import P2 from './p2';
import P3 from './p3';
import P4 from './p4';
import P5 from './p5';
import P6 from './p6';
import P7 from './p7';

const steps = ['BUSINESS CONTACT INFORMATION', 'PRIMARY OWNER INFORMATION', 'BUSINESS/TRADE IDENTIFICATION', 'VENDOR BACKGROUND', 'COMPANY DETAILS', 'AGREEMENT', 'SIGNATURE'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <>    
        </>;
    case 1:
      return <P2 />;
    case 2:
      return <P3 />;
    case 3:
      return <P4 />;
    case 4:
      return <P5 />;
    case 5:
      return <P6 />;
    case 6:
      return <P7 />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {

    if(activeStep==0)
    {
      if(data[0].pa_1=="" ||data[0].pa_2=="" ||data[0].pa_3=="" ||data[0].pa_4=="" ||data[0].pa_5=="" || validateEmail (data[0].pa_6 )==false ||data[0].pa_8==""||data[0].pa_9==""||validate_pa_9()==false)
      {
        setError(true)

      }else {
        setError(false)
        setActiveStep(activeStep + 1);
      }

    }else if(activeStep==1)
    {
      if(data[0].pb_1=="" ||data[0].pb_2=="" || validateEmail (data[0].pb_3 )==false ||data[0].pb_4.length<12 ||data[0].pb_5.length<12  )
      {
        setError(true)

      }else {
        setError(false)
        setActiveStep(activeStep + 1);
      }

    }else if(activeStep==2)
    {
      if(Pc_27__()==false)
      {
        setError(true)
        

      }else{
        setError(false)
        setActiveStep(activeStep + 1);

      }
      

       
      

    }else if(activeStep==3)
    {
      
      if(zip[0].zip==""||zip[0].zip=="0000" || data_z[0].pd_1=="" ||data_z[0].pd_2=="" ||data_z[0].pd_3=="" ||data_z[0].pd_4=="" ||data_z[0].pd_5==""||data_z[0].pd_6==""|| Pd6_()==false ||Pd11_()==false ||Pd16_()==false || Pd21_()==false ||Pd26_()==false ||Pd28_()==false ||  data_z[0].pd_11==""||  data_z[0].pd_16=="" || data_z[0].pd_21==""|| data_z[0].pd_26=="" || data_z[0].pd_28==""  )
      {
        // 11 16
        setError(true)

      }else {
        setError(false)
        setActiveStep(activeStep + 1);
      }
      

    }else if(activeStep==4)
    {
      setError(false)
        setActiveStep(activeStep + 1);

    }else if(activeStep==5)
    {
      if(data_z[0].pf_1 ==true)
      {
        setError(false)
        setActiveStep(activeStep + 1);

      }else{
        setError(true)
      }
      

    }else if(activeStep==6)
    {
      setError(false)

      if(data_z[0].pg_1.length<4)
      {
        alert("Signature invalid?")

      }else{
        setActiveStep(activeStep + 1);
      }
      

    }
   

    if(activeStep === steps.length-1)
    {
      
        add()

    
      
      console.log("run fetch")
    
    }
 

  };


  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    return isValidEmail;
  };
  const Pd6_ = () => {

    if(data_z[0].pd_6=="Yes")
    {
      if(data_z[0].pd_7.length<3 ||data_z[0].pd_8.length<3||data_z[0].pd_9.length<3||data_z[0].pd_10.length<3)
      {
        return false
      }else{
        return true

      }

    }else{
      return true
    }
  
  };

  const Pd26_ = () => {

    if(data_z[0].pd_26=="Yes")
    {
      if( data_z[0].pd_27.length<2 )
      {
        return false
      }else{
        return true
      }

    }else{
      return true
    }
  
  };

  const Pd28_ = () => {

    if(data_z[0].pd_28=="Yes")
    {
      if( data_z[0].pd_29.length<12 )
      {
        return false
      }else{
        return true
      }

    }else{
      return true
    }
  
  };

  const Pd21_ = () => {

    if(data_z[0].pd_21=="Yes")
    {
      if( data_z[0].pd_22.length<3 ||data_z[0].pd_23.length<3 || data_z[0].pd_24.length<3 ||data_z[0].pd_25.length<3)
      {
        return false
      }else{
        return true
      }

    }else{
      return true
    }
  
  };
  const Pd16_ = () => {

    if(data_z[0].pd_16=="Yes")
    {
      if( data_z[0].pd_17.length<3 ||data_z[0].pd_18.length<3 || data_z[0].pd_19.length<3 ||data_z[0].pd_20.length<3)
      {
        return false
      }else{
        return true
      }

    }else{
      return true
    }
  
  };

  const Pc_27__ = () => {

    if(data[0].pc_11)
    {
      if( data[0].pc_27.length<8)
      {
        return false
      }else{
        return true
      }

    }else{
      return true
    }
  
  };


  const Pd11_ = () => {

    if(data_z[0].pd_11=="Yes")
    {
      if( data_z[0].pd_12.length<3 ||data_z[0].pd_13.length<3 || data_z[0].pd_14.length<3 ||data_z[0].pd_15.length<3)
      {
        return false
      }else{
        return true

      }

    }else{
      return true
    }
  
  };

  const formatPhoneNumber = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return formattedPhoneNumber;
  };


  const validate_pa_9 = () => {

    if(data[0].pa_10.length==0|| data[0].pa_10.length==11)
    {
      return true
      
    }else{
     return false

    }

    if(data[0].pa_11.length==0|| data[0].pa_11.length==10)
    {
      return true
      
    }else{
     return false

    }

   
    
    
    
  };


  const add = async () => {
    const a=JSON.stringify(data)
    const b=JSON.stringify(data_z)

    await fetch(`/api/add.php?data=${a}&data_z=${b}&id=${a}`)
      .then((response) => response.json())
      .then((json) => {
        
        
        console.log("account active",json)
        if(json[0].msg=="pass")
        {
          setid(json[0].id)
          const id=json[0].id
          const i=0;
          console.log("save array and get id=",id);
          add_zip(id,zip[0].zip,0)
        }


      })
      .catch((err) => {
        console.log(err);
      });
  };


  const add_zip = async (id,code,i) => {
    console.log("i is ",i)
if(i<zip.length)
{
  await fetch(`/api/add_zip.php?zip=${code}&id=${id}`)
  .then((response) => response.json())
  .then((json) => {
    
    
    console.log(i," add zip",json)
    i++;
    add_zip(id,zip[i].zip,i)
    


  })
  .catch((err) => {
    console.log(err);
  });
}


  };




  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
   const [error, setError]   = React.useState(false);

  const [data, setData]   = React.useState([
    {
      pa_1:"",
      pa_2:"",
      pa_3:"",
      pa_4:"",
      pa_5:"",
      pa_6:"",
      pa_7:"",
      pa_8:"",
      pa_9:"",
      pa_10:"",
      pa_11:"",
  
      pb_1:"",
      pb_2:"",
      pb_3:"",
      pb_4:"",
      pb_5:"",
      pb_6:"",
  
      pc_1:false,
      pc_2:false,
      pc_3:false,
      pc_4:false,
      pc_5:false,
      pc_6:false,
      pc_7:false,
      pc_8:false,
      pc_9:false,
      pc_10:false,
      pc_11:false,
      pc_12:false,
      pc_13:false,
      pc_14:false,
      pc_15:false,
      pc_16:false,
      pc_17:false,
      pc_18:false,
      pc_19:false,
      pc_20:false,
      pc_21:false,
      pc_22:"",
      pc_23:"",
      pc_24:"",
      pc_25:"",
      pc_26:"",
      pc_27:"",
  

  

  

  
  
    }
  ]);

  const [data_z, setData_z]   = React.useState([
    {
      pd_1:"",
      pd_2:"",
      pd_3:"",
      pd_4:"",
      pd_5:"",
      pd_6:"",
      pd_7:"",
      pd_8:"",
      pd_9:"",
      pd_10:"",
      pd_11:"",
      pd_12:"",
      pd_13:"",
      pd_14:"",
      pd_15:"",
      pd_16:"",
      pd_17:"",
      pd_18:"",
      pd_19:"",
      pd_20:"",
      pd_21:"",
      pd_22:"",
      pd_23:"",
      pd_24:"",
      pd_25:"",
      pd_26:"",
      pd_27:"",
      pd_28:"",
      pd_29:"",
  
      pe_1:false,
      pe_2:false,
      pe_3:false,
      pe_4:false,
      pe_5:false,
      pe_6:false,
      pe_7:false,
      pe_8:false,
      pe_9:false,
      pe_10:false,
      pe_11:false,
      pe_12:"",
  
      pf_1:false,
  
      pg_1:"",
      pg_2:"",
      pg_3:"",
      pg_4:"",
  
  
    }
  ]);
  
  const [zip, setZip]   = React.useState([{zip:"0000"}])
  const [id, setid]   = React.useState("")

  const zipAdd = () => {
    // Create a new array by spreading the existing 'zip' array and adding a new object
    setZip([...zip, {zip: ""}]);
};

const zipDelete = (index) => {
  // Create a new array by filtering out the object at the specified index
  const newZipArray = zip.filter((_, i) => i !== index);
  setZip(newZipArray);
};

const changeZip = (i, changes) => {
  
  const oldArr = [...zip];
  const oldObj = oldArr[i];
  oldArr[i] = { ...oldObj, ...changes };
  console.log("abc is here",i,changes)
  setZip(oldArr);

}

  const changeDataArray = (i, changes) => {
  
      const oldArr = [...data];
      const oldObj = oldArr[i];
      oldArr[i] = { ...oldObj, ...changes };
      console.log("abc is here",i,changes)
      setData(oldArr);
  
  }
  const changeDataArrayZ = (i, changes) => {
  
      const oldArr = [...data_z];
      const oldObj = oldArr[i];
      oldArr[i] = { ...oldObj, ...changes };
      console.log("abc is here",i,changes)
      setData_z(oldArr);
  
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
       
      </AppBar>
      <div style={{padding:'40px',margin:"20px"}} component="main" maxWidth="sm" sx={{ mb: 4 }}>
         <center><h3>Footprint Management Services</h3></center>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label,i) => (
              <Step key={label}>
                <StepLabel
                // onClick={()=>{  setActiveStep(i)  }} 
                  >{label}</StepLabel>
                

              </Step>
            ))}
          </Stepper>
          
          {activeStep === steps.length ? (
            <React.Fragment>
              {/* <Typography variant="h5" gutterBottom>
                Thank you.
              </Typography> */}
              <Typography variant="subtitle1">
              <a href={"https://footprintmgmt.com"}> <Button variant="contained">Back</Button> </a> 
              <br/>
              <br/>
              Thank you for submitting the form. We will contact you once a suitable job opportunity becomes available.
              
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>


              {getStepContent(activeStep)}

              {/* step 0 start*/}

              
              {data.length==0?<></>: activeStep==0?<>
                <div className="conatiner"
          id="collapseOne"
          
          aria-labelledby="headingOne"
          data-parent="#accordion"
          
        >
          <div className="card-body">
            <h4>
            BUSINESS CONTACT INFORMATION
            </h4>
            <br/>
           
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""  style={{color: error != true? 'black' : data[0].pa_1==""?"red":"black"}}  >Company Name*</label>
                  <input
                    type="text"
                    required=""
                    name="companyname"
                    className="form-control"
                    value={data[0].pa_1}
                    onChange={(e)=>{changeDataArray(0,{pa_1:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" style={{color: error != true? 'black' : data[0].pa_2==""?"red":"black"}}>Business Address*</label>
                  <input 
                    type="text"
                    required=""
                    name="businessaddres"
                    className="form-control"
                    value={data[0].pa_2}
                    onChange={(e)=>{changeDataArray(0,{pa_2:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="" style={{color: error != true? 'black' : data[0].pa_3==""?"red":"black"}}>City*</label>
                      <input
                        type="text"
                        required=""
                        name="businessaddres"
                        className="form-control"
                        value={data[0].pa_3}
                        onChange={(e)=>{changeDataArray(0,{pa_3:e.target.value})}}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="" style={{color: error != true? 'black' : data[0].pa_4==""?"red":"black"}} >State*</label>
                      <input
                        type="text"
                        required=""
                        name="businessaddres"
                        className="form-control"
                        value={data[0].pa_4}
                        onChange={(e)=>{changeDataArray(0,{pa_4:e.target.value})}}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="" style={{color: error != true? 'black' : data[0].pa_5==""?"red":"black"}}>Zip Code*</label>
                      <input
                        type="text"
                        required=""
                        name="businessaddres"
                        className="form-control"
                        value={data[0].pa_5}
                        onChange={(e)=>{changeDataArray(0,{pa_5:e.target.value})}}
                      />
                    </div>
                  </div>
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : validateEmail(data[0].pa_6)==""?"red":"black"}} >
                    E-mail*
                     {/* {validateEmail(data[0].pa_6)?"true":"flase"} */}
                  </label>
                  <input
                    type="email"
                    required=""
                    name="emailbussines"
                    data-parsley-trigger="change"
                    className="form-control"
                    value={data[0].pa_6}
                    onChange={(e)=>{changeDataArray(0,{pa_6:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Company Website
                  </label>
                  <input
                    type="url"
                    data-parsley-type="url"
                    name="websitecomp"
                    className="form-control"
                    value={data[0].pa_7}
                    onChange={(e)=>{changeDataArray(0,{pa_7:e.target.value})}}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data[0].pa_8.length==12?"black":"red"}}>
                    Telephone Number*
                  </label>
                  <input
                    type="text"
                    required=""
                    data-parsley-type="digits"
                    data-parsley-maxlength={10}
                    className="form-control"
                    
                    maxLength={13}
                    name="abc"
                    value={data[0].pa_8}
                    placeholder='(xxx)xxx-xxxx'
                    onChange={(e)=>{

                      

                      changeDataArray(0,{pa_8:formatPhoneNumber(e.target.value)})

                      }

                      
                    }
                  />
                  <br/>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data[0].pa_9==""?"red":"black"}} >
                        Organization Type*
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        
                        type="radio"
                        name="organizationtype"
                        id="pa_9_a"
                        defaultValue="Sole proprietorship"
                        defaultChecked={data[0].pa_9 === "Sole proprietorship"}
                        onChange={(e)=>{changeDataArray(0,{pa_9:e.target.value})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pa_9_a"
                      >
                        Sole proprietorship
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        required=""
                        type="radio"
                        name="organizationtype"
                        id="pa_9_b"
                        defaultValue="Partnership"
                        defaultChecked={data[0].pa_9 === "Partnership"}
                        onChange={(e)=>{changeDataArray(0,{pa_9:e.target.value})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pa_9_b"
                      >
                        Partnership
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        required=""
                        type="radio"
                        name="organizationtype"
                        id="pa_9_c"
                        defaultValue="LLC"
                        defaultChecked={data[0].pa_9 === "LLC"}
                        onChange={(e)=>{changeDataArray(0,{pa_9:e.target.value})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pa_9_c"
                      >
                        LLC
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        required=""
                        type="radio"
                        name="organizationtype"
                        id="pa_9_d"
                        defaultValue="Corporation"
                        defaultChecked={data[0].pa_9 === "Corporation"}
                        onChange={(e)=>{changeDataArray(0,{pa_9:e.target.value})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pa_9_d"
                      >
                        Corporation
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        required=""
                        type="radio"
                        name="organizationtype"
                        id="pa_9_e"
                        defaultValue="S-Corp"
                        defaultChecked={data[0].pa_9 === "S-Corp"}
                        onChange={(e)=>{changeDataArray(0,{pa_9:e.target.value})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pa_9_e"
                      >
                        S-Corp
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        required=""
                        type="radio"
                        name="organizationtype"
                        id="pa_9_f"
                        defaultValue="Other"
                        defaultChecked={data[0].pa_9 === "Other"}
                        onChange={(e)=>{changeDataArray(0,{pa_9:e.target.value})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pa_9_f"
                      >
                        Other
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">

                    {
                      true?<>
                                          <div className="form-group">
                      <label htmlFor="" className="control-label" style={{color: error != true? 'black' : validate_pa_9()==""?"red":"black"}} >
                        SSN* 
                      </label>
                      <input name="ssn" type="text" className="form-control" maxLength={11}
                          value={data[0].pa_10}
                          onChange={(e)=>{

                            var p=e.target.value;
                            var v="";
      
                            if(p.length<3)
                            {
                              v=e.target.value;
      
                            }
                            else if(p.length==3)
                            {
                              v=e.target.value+"-";
      
                            }else if(p.length<6)
                            {
                              v=e.target.value;
                            }else if(p.length==6)
                            {
                              v=e.target.value+"-";
                            }else{
                              v=e.target.value;
                            }
      
                            changeDataArray(0,{pa_10:v})
      
                            }
      
                            
                          }
                          />
                      {/* <br />
                      <div className="alert alert-info small">
                        Required if you wish to receive remittance as an
                        individual.
                      </div> */}
                    </div>
                    </>:data[0].pa_9==="Partnership"?<>
                    <div className="form-group">
                      <label htmlFor="" className="control-label" style={{color: error != true? 'black' : validate_pa_9()==""?"red":"black"}} >
                        SSN*
                      </label>
                      <input name="ssn" type="text" className="form-control" maxLength={10}
                          value={data[0].pa_10}
                          onChange={(e)=>{

                            var p=e.target.value;
                            var v="";
      
                            if(p.length<3)
                            {
                              v=e.target.value;
      
                            }
                            else if(p.length==3)
                            {
                              v=e.target.value+"-";
      
                            }else if(p.length<6)
                            {
                              v=e.target.value;
                            }else if(p.length==6)
                            {
                              v=e.target.value+"-";
                            }else{
                              v=e.target.value;
                            }
      
                            changeDataArray(0,{pa_10:v})
      
                            }
      
                            
                          }
                          />
                      <br />
                      {/* <div className="alert alert-info small">
                        Required if you wish to receive remittance as an
                        individual.
                      </div> */}
                    </div></>:<></>
                    }
                    <br/>

                    {
                      false?<></>:
                      <>
                         <div className="form-group">
                      <label htmlFor="" className="control-label" style={{color:"black"}}>
                        Federal ID #*
                      </label>
                      <input
                        name="federalid"
                        type="text"
                        maxLength={10}
                        className="form-control"
                        value={data[0].pa_11}
                        onChange={(e)=>{

                          var p=e.target.value;
                          var v="";
    
                          if(p.length<2)
                          {
                            v=e.target.value;
    
                          }
                          else if(p.length==2)
                          {
                            v=e.target.value+"-";
    
                          }else{
                            v=e.target.value;
                          }
    
                          changeDataArray(0,{pa_11:v})
    
                          }
    
                          
                        }
                      />
                      {/* <br />
                      <div className="alert alert-info small">
                        Required if you wish to receive remittance as a business
                      </div> */}
                    </div>
                      </>
                    }






                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>:<></>
              }

{/* step 0 end */}

{/* step 1 start */}
{data.length==0?<></>: activeStep==1?<>
<div className="card-body">
            <h4>PRIMARY OWNER INFORMATION</h4>
            <br/>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data[0].pb_1==""?"red":"black"}}>
                    First Name*
                  </label>
                  <input
                    type="text"
                    required=""
                    className="form-control"
                    name="firstname"
                    value={data[0].pb_1}
                    onChange={(e)=>{changeDataArray(0,{pb_1:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data[0].pb_2==""?"red":"black"}}>
                    Last Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="lastnameowner"
                    value={data[0].pb_2}
                    onChange={(e)=>{changeDataArray(0,{pb_2:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : validateEmail(data[0].pb_3)==false?"red":"black"}}>
                    Email Address*
                  </label>
                  <input
                    required=""
                    type="email"
                    className="form-control"
                    data-parsley-trigger="change"
                    name="emailowner"
                    value={data[0].pb_3}
                    onChange={(e)=>{changeDataArray(0,{pb_3:e.target.value})}}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data[0].pb_4.length<12?"red":"black"}}>
                    Contact Number*
                  </label>
                  <input
                    type="text"
                    required=""
                    className="form-control"
                    name="contactnumber"
                    placeholder='(xxx)xxx-xxxx'
                    maxLength={13}
                    value={data[0].pb_4}
                    onChange={(e)=>{

                      var p=e.target.value;
                      var v="";
                      if(p.length==1)
                      {
                        v="("+e.target.value;
                      }else if(p.length<4)
                      {
                        v=e.target.value;
                      }else if(p.length==4)
                      {
                        v=e.target.value+")";
                      }else if(p.length<8)
                      {
                        v=e.target.value;
                      }else if(p.length==8)
                      {
                        v=e.target.value+"-";
                      }else{
                        v=e.target.value;
                      }

                      changeDataArray(0,{pb_4:formatPhoneNumber(e.target.value)})

                      }
                    }

                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data[0].pb_5.length<12?"red":"black"}}>
                    Mobile Number*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    placeholder='(xxx)xxx-xxxx'
                    maxLength={13}
                  
                    data-parsley-type="digits"
                    name="mobilenumber"
                    value={data[0].pb_5}
                    onChange={(e)=>{

                     

                      changeDataArray(0,{pb_5:formatPhoneNumber(e.target.value)})
                      

                      }
                    }
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Fax Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="faxnumber"
                    value={data[0].pb_6}
                    placeholder='(xxx)xxx-xxxx'
                    maxLength={13}
                    onChange={(e)=>{

                      var p=e.target.value;
                      

                      changeDataArray(0,{pb_6:formatPhoneNumber(p)})

                      }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          </>:<></>}
{/* step 1 End */}

{/* step 2 start */}
{data.length==0?<></>: activeStep==2?<>
<div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    
                    <strong>Services Offered</strong>
                    
                  </label>
                </div>
                <br/>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        data-parsley-mincheck={1}
                        id="pc_1"
                        defaultValue="General Contractor"
                        checked={data[0].pc_1}
                        onChange={()=>{changeDataArray(0,{pc_1:(!data[0].pc_1)})}}

                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_1"
                      >
                        General Contractor
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_2"
                        defaultValue="Roofing"
                        checked={data[0].pc_2}
                        onChange={()=>{changeDataArray(0,{pc_2:(!data[0].pc_2)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_2"
                      >
                        Roofing
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_3"
                        defaultValue="Winterizations"
                        checked={data[0].pc_3}
                        onChange={()=>{changeDataArray(0,{pc_3:(!data[0].pc_3)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_3"
                      >
                        Winterizations
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_4"
                        defaultValue="Mold Assessment"
                        checked={data[0].pc_4}
                        onChange={()=>{changeDataArray(0,{pc_4:(!data[0].pc_4)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_4"
                      >
                        Mold Assessment
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_5"
                        defaultValue="Mold Remediation"
                        checked={data[0].pc_5}
                        onChange={()=>{changeDataArray(0,{pc_5:(!data[0].pc_5)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_5"
                      >
                        Mold Remediation
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_6"
                        defaultValue="Rekey"
                        checked={data[0].pc_6}
                        onChange={()=>{changeDataArray(0,{pc_6:(!data[0].pc_6)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_6"
                      >
                        Rekey
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_7"
                        defaultValue="Pool Care"
                        checked={data[0].pc_7}
                        onChange={()=>{changeDataArray(0,{pc_7:(!data[0].pc_7)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_7"
                      >
                        Pool Care
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_8"
                        defaultValue="Yard Care"
                        checked={data[0].pc_8}
                        onChange={()=>{changeDataArray(0,{pc_8:(!data[0].pc_8)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_8"
                      >
                        Yard Care
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_9"
                        defaultValue="Inspection Services"
                        checked={data[0].pc_9}
                        onChange={()=>{changeDataArray(0,{pc_9:(!data[0].pc_9)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_9"
                      >
                        Inspection Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_10"
                        defaultValue="Recurring Services"
                        checked={data[0].pc_10}
                        onChange={()=>{changeDataArray(0,{pc_10:(!data[0].pc_10)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_10"
                      >
                        Recurring Services
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_11"
                        defaultValue="Appraisals"
                        checked={data[0].pc_11}
                        onChange={()=>{changeDataArray(0,{pc_11:(!data[0].pc_11)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_11"
                      >
                      Realtor
                      </label>
                      {/* a1 pc_11 pc_27 */}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_13"
                        defaultValue="Lock Change"
                        checked={data[0].pc_13}
                        onChange={()=>{changeDataArray(0,{pc_13:(!data[0].pc_13)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_13"
                      >
                        Lock Change
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_14"
                        defaultValue="Debris Removal"
                        checked={data[0].pc_14}
                        onChange={()=>{changeDataArray(0,{pc_14:(!data[0].pc_14)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_14"
                      >
                        Debris Removal
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_15"
                        defaultValue="Hazard Removal"
                        checked={data[0].pc_15}
                        onChange={()=>{changeDataArray(0,{pc_15:(!data[0].pc_15)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_15"
                      >
                        Hazard Removal
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_16"
                        defaultValue="Minor Repairs"
                        checked={data[0].pc_16}
                        onChange={()=>{changeDataArray(0,{pc_16:(!data[0].pc_16)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_16"
                      >
                        Minor Repairs
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_17"
                        defaultValue="Vehicle Removal"
                        checked={data[0].pc_17}
                        onChange={()=>{changeDataArray(0,{pc_17:(!data[0].pc_17)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_17"
                      >
                        Vehicle Removal
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_18"
                        defaultValue="Electrical Services"
                        checked={data[0].pc_18}
                        onChange={()=>{changeDataArray(0,{pc_18:(!data[0].pc_18)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_18"
                      >
                        Electrical Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_19"
                        defaultValue="Janitorial Services"
                        checked={data[0].pc_19}
                        onChange={()=>{changeDataArray(0,{pc_19:(!data[0].pc_19)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_19"
                      >
                        Janitorial Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_20"
                        defaultValue="Plumbing Repairs"
                        checked={data[0].pc_20}
                        onChange={()=>{changeDataArray(0,{pc_20:(!data[0].pc_20)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_20"
                      >
                        Plumbing Repairs
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_21"
                        defaultValue="OtherService"
                        checked={data[0].pc_21}
                        onChange={()=>{changeDataArray(0,{pc_21:(!data[0].pc_21)})}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_21"
                      >
                        Other- Please explain:
                      </label>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Other</label>
                      <textarea
                        className="form-control"
                        name="otherexplain"
                        
                        value={data[0].pc_22}
                        onChange={(e)=>{changeDataArray(0,{pc_22:e.target.value})}}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    <strong>License Identification</strong>
                  </label>
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    {" "}
                    General Contractors License #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="generalcontractor"
                    value={data[0].pc_23}
                    maxLength={10}
                    onChange={(e)=>{changeDataArray(0,{pc_23:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    {" "}
                    Trade License #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="tradelicense31"
                    value={data[0].pc_24}
                    maxLength={10}
                    onChange={(e)=>{changeDataArray(0,{pc_24:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    {" "}
                    Trade License #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="tradelicense32"
                    maxLength={10}
                    value={data[0].pc_25}
                    onChange={(e)=>{changeDataArray(0,{pc_25:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    {" "}
                    Trade License #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="tradelicense33"
                    value={data[0].pc_26}
                    maxLength={10}
                    onChange={(e)=>{changeDataArray(0,{pc_26:e.target.value})}}
                  />
                </div>
                <br/>
                {
                  data[0].pc_11==true?<><div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data[0].pc_27.length<8?"red":"black"}}>Real Estate License #</label>
                  <input
                    type="text"
                    className="form-control"
                    name="otherlicense3"
                    maxLength={10}
                    value={data[0].pc_27}
                    onChange={(e)=>{changeDataArray(0,{pc_27:e.target.value})}}
                  />
                </div></>:<></>
                }
                
              </div>
            </div>
          </div>
          </>:<></>}
{/* step 2 End */}

{/* step 3 start */}
{data.length==0?<>
</>: activeStep==3?<>
<div className="card-body">
            <h4>VENDOR BACKGROUND</h4>
            <br/>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label"  style={{color: error != true? 'black' : data_z[0].pd_1==""?"red":"black"}} >
                    Are you or your company involved in any active litigation as
                    a result of work performed?* (If Yes, please explain)
                  </label>
                  <textarea
                    name="litigation"
                    className="form-control"
                    defaultValue={""}
                    value={data_z[0].pd_1}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_1:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label"  style={{color: error != true? 'black' : data_z[0].pd_2==""?"red":"black"}} >
                    Have you or any of your employees been convicted of a
                    felony, a personal property crime, or been required to
                    register as a sex offender?* (If Yes, please explain)
                  </label>
                  <textarea
                    name="convicted"
                    className="form-control"
                    defaultValue={""}
                    value={data_z[0].pd_2}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_2:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label"  style={{color: error != true? 'black' : data_z[0].pd_3==""?"red":"black"}} >
                    Do you authorize Footprint to conduct a criminal background
                    check on you and your company?*
                  </label>
                </div>
                <br/>

                {/* start */}
                <div className="form-check">
                  <input
                    className="form-check-input criminal"
                    type="radio"
                    required=""
                    name="n1"
                    id="pd_3_y"
                    value={"Yes"}
                    
                    
                        onChange={(e)=>{changeDataArrayZ(0,{pd_3:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd_3_y">
                    Yes
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input criminal "
                    required=""
                    type="radio"
                    name="n1"
                    id="pd_3_n"
                    value={"NO"}
                    
                        onChange={(e)=>{changeDataArrayZ(0,{pd_3:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd_3_n">
                    No
                  </label>
                </div>
                {/* end */}
               
                <br/>
                
                <br />
                <div className="form-group">
                  <label htmlFor="" className="control-label"  style={{color: error != true? 'black' : data_z[0].pd_4==""?"red":"black"}} >
                    Are you a minority owned company?*
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="minorityowned4"
                    id="pd_4_y"
                    defaultValue="Yes"
                    defaultChecked={data_z[0].pd_4 === "Yes"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_4:e.target.value})}}
                  />
                  <label
                    className="form-check-label"
                    
                    htmlFor="pd_4_y"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="minorityowned4"
                    id="pd_4_n"
                    defaultValue="No"
                    defaultChecked={data_z[0].pd_4 === "No"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_4:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd_4_n">
                    No
                  </label>
                </div>
                <br />

                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data_z[0].pd_5==""?"red":"black"}} >
                    Are you a veteran owned company?*
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="veteranowned4"
                    id="pd5_y"
                    defaultValue="Yes"
                    defaultChecked={data_z[0].pd_5 === "Yes"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_5:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd5_y">
                    Yes
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="veteranowned4"
                    id="pd5_n"
                    defaultValue="No"
                    defaultChecked={data_z[0].pd_5 === "No"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_5:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd5_n">
                    No
                  </label>
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="" className="control-label"  style={{color: error != true? 'black' : data_z[0].pd_6==""?"red":"black"}} >
                    Does your company carry General Liability Insurance?*
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="liabilityinsurance4"
                    id="pd_6_y"
                    defaultValue="Yes"
                    defaultChecked={data_z[0].pd_6 === "Yes"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_6:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd_6_y">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="liabilityinsurance4"
                    id="pd_6_n"
                    defaultValue="No"
                    defaultChecked={data_z[0].pd_6 === "No"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_6:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd_6_n">
                    No
                  </label>
                </div>
              </div>
              {
                data_z[0].pd_6=="Yes"?<>
                <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color:data_z[0].pd_7.length<3?"red":"black"}}>
                    Carrier
                  </label>
                
                  <input type="text" className="form-control" name="carrier4" value={data_z[0].pd_7} onChange={(e)=>{changeDataArrayZ(0,{pd_7:e.target.value})}} />
                </div>
                
                <div className="form-group">
                  <label htmlFor="" className="control-label"style={{color:data_z[0].pd_8.length<3?"red":"black"}}>
                    Policy #
                  </label>
                 
                  <input type="text" className="form-control" name="policy4" value={data_z[0].pd_8} onChange={(e)=>{changeDataArrayZ(0,{pd_8:e.target.value})}} />
                </div>
              </div>
              
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color:data_z[0].pd_9.length<3?"red":"black"}}>
                    Expiration
                  </label>
                  <br/>
                  <input
                    type="date"
                    className="form-control"
                    name="expiration4"
                    value={data_z[0].pd_9} onChange={(e)=>{changeDataArrayZ(0,{pd_9:e.target.value})}}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color:data_z[0].pd_10.length<3?"red":"black"}}>
                    Coverage
                  </label>
                  <br/>
                  <input
                    type="text"
                    className="form-control"
                    name="coverage4"
                    value={data_z[0].pd_10} onChange={(e)=>{changeDataArrayZ(0,{pd_10:e.target.value})}}
                  />
                </div>
              </div>
              <br />
              </>:<></>
              }

              
              
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data_z[0].pd_11==""?"red":"black"}}>
                    <br/>
                    Does your company carry Errors and Omissions Insurance?*
                  </label>
                </div>
                
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="omissionsinsurance4"
                    id="pd_7_y"
                    defaultValue="Yes"
                    defaultChecked={data_z[0].pd_11 === "Yes"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_11:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd_7_y">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="omissionsinsurance4"
                    id="pd_7_n"
                    defaultValue="No"
                    
                    defaultChecked={data_z[0].pd_11 === "No"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_11:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd_7_n">
                    No
                  </label>
                </div>
              </div>
              {
                data_z[0].pd_11=="Yes"?<>
                <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color:data_z[0].pd_12.length<3?"red":"blace"}} >
                    Carrier
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="carrier42"
                    value={data_z[0].pd_12} onChange={(e)=>{changeDataArrayZ(0,{pd_12:e.target.value})}}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color:data_z[0].pd_13.length<3?"red":"blace"}}>
                    Policy #
                  </label>
                  <input type="text" className="form-control" name="policy42" value={data_z[0].pd_13} onChange={(e)=>{changeDataArrayZ(0,{pd_13:e.target.value})}}  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color:data_z[0].pd_14.length<3?"red":"blace"}}>
                    Expiration
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="expiration42"
                    value={data_z[0].pd_14} onChange={(e)=>{changeDataArrayZ(0,{pd_14:e.target.value})}}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color:data_z[0].pd_15.length<3?"red":"blace"}} >
                    Coverage
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="coverage42"
                    value={data_z[0].pd_15} onChange={(e)=>{changeDataArrayZ(0,{pd_15:e.target.value})}}
                  />
                </div>
              </div>
              <br />
              </>:<></>
              }

              

           
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data_z[0].pd_16==""?"red":"black"}} >
                    <br/>
                    Does your company carry Commercial Auto Insurance?*
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="autoinsurance41"
                    id="no_pd"
                    defaultValue="Yes"
                    defaultChecked={data_z[0].pd_16 === "Yes"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_16:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="no_pd">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="autoinsurance41"
                    id="ui"
                    defaultValue="No"
                    defaultChecked={data_z[0].pd_16 === "No"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_16:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="ui">
                    No
                  </label>
                </div>
              </div>

              {
                data_z[0].pd_16=="Yes"?<>
                <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: data_z[0].pd_17.length<3?"red":"black"}} >
                    Carrier
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="carrier43"
                    value={data_z[0].pd_17} onChange={(e)=>{changeDataArrayZ(0,{pd_17:e.target.value})}}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label"  style={{color: data_z[0].pd_18.length<3?"red":"black"}} >
                    Policy #
                  </label>
                  <input type="text" className="form-control" name="policy43" value={data_z[0].pd_18} onChange={(e)=>{changeDataArrayZ(0,{pd_18:e.target.value})}} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: data_z[0].pd_19.length<3?"red":"black"}} >
                    Expiration
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="expiration43"
                    value={data_z[0].pd_19} onChange={(e)=>{changeDataArrayZ(0,{pd_19:e.target.value})}}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label"  style={{color: data_z[0].pd_20.length<3?"red":"black"}} >
                    Coverage
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="coverage43"
                    value={data_z[0].pd_20} onChange={(e)=>{changeDataArrayZ(0,{pd_20:e.target.value})}}
                  />
                </div>
              </div>
              <br />
              </>:<></>
              }
              
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label"style={{color: error != true? 'black' : data_z[0].pd_21==""?"red":"black"}}>
                    <br/>
                    Does your company carry Worker’s Comp Insurance?*
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="workersinsurance41"
                    id="y1"
                    defaultValue="Yes"
                    defaultChecked={data_z[0].pd_21 === "Yes"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_21:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="y1">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="workersinsurance41"
                    id="y2"
                    defaultValue="No"
                    defaultChecked={data_z[0].pd_21 === "No"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_21:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="y2">
                    No
                  </label>
                </div>
              </div>



              {data_z[0].pd_21 === "Yes"?<>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: data_z[0].pd_22.length<3?"red":"black"}}>
                    Carrier
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="carrier44"
                    value={data_z[0].pd_22} onChange={(e)=>{changeDataArrayZ(0,{pd_22:e.target.value})}}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: data_z[0].pd_23.length<3?"red":"black"}}>
                    Policy #
                  </label>
                  <input type="text" className="form-control" name="policy44" value={data_z[0].pd_23} onChange={(e)=>{changeDataArrayZ(0,{pd_23:e.target.value})}} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: data_z[0].pd_24.length<3?"red":"black"}}>
                    Expiration
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="expiration44"
                    value={data_z[0].pd_24} onChange={(e)=>{changeDataArrayZ(0,{pd_24:e.target.value})}}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: data_z[0].pd_25.length<3?"red":"black"}}>
                    Coverage
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="coverage44"
                    value={data_z[0].pd_25} onChange={(e)=>{changeDataArrayZ(0,{pd_25:e.target.value})}}
                  />
                </div>
              </div>
              <br />
              </>:<></>
                
              }
              
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data_z[0].pd_26==""?"red":"black"}}>
                    <br/>
                    Does your company maintain an Aspen Grove ABC Number?*
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="abcnumber42"
                    id="pd_26y"
                    defaultValue="Yes"
                    defaultChecked={data_z[0].pd_26 === "Yes"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_26:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd_26y">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="abcnumber42"
                    id="p26"
                    defaultValue="No"
                    defaultChecked={data_z[0].pd_26 === "No"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_26:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="p26">
                    No
                  </label>
                </div>
{
  data_z[0].pd_26 === "Yes"?<>
  <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: data_z[0].pd_27.length<2?"red":"black"}}>
                    What is your ABC Number:
                  </label>
                  <input type="text" className="form-control" name="abc4" value={data_z[0].pd_27} onChange={(e)=>{changeDataArrayZ(0,{pd_27:e.target.value})}} />
                </div>
                <br />
                </>:<></>
}
                




                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : data_z[0].pd_28==""?"red":"black"}}>
                    Does your company provide 24-hour emergency services?*
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="emergency4"
                    id="pds"
                    defaultValue="Yes"
                    defaultChecked={data_z[0].pd_28=="Yes"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_28:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pds">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="emergency4"
                    id="pd28n"
                    defaultValue="No"
                    defaultChecked={data_z[0].pd_28=="No"}
                    onChange={(e)=>{changeDataArrayZ(0,{pd_28:e.target.value})}}
                  />
                  <label className="form-check-label" htmlFor="pd28n">
                    No
                  </label>
                </div>


                {
                  data_z[0].pd_28=="Yes"?<>
                  <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: data_z[0].pd_29.length<12?"red":"black"}} >
                    <br/>
                    What is your emergency contact phone number?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="emergency44"
                    value={data_z[0].pd_29}       
                    placeholder='(xxx)xxx-xxxx'
                    maxLength={13}
                    onChange={(e)=>{
                      
                      changeDataArrayZ(0,{pd_29:formatPhoneNumber(e.target.value)})
                      }
                      
                    }            />
                </div>
                <br />
                </>:<></>
                }
                
                <div className="form-group">
                  <label htmlFor="" className="control-label" style={{color: error != true? 'black' : zip[0].zip==""||zip[0].zip=="0000"?"red":"black"}} >
                    What is your Primary Service Area?* (Detailing current zip codes and counties):
                  </label>
                  {/* <textarea
                    className="form-control"
                    name="servicearea4"
                    defaultValue={""}
                    //value={data_z[0].pd_27} onChange={(e)=>{changeDataArrayZ(0,{pd_27:e.target.value})}}
                  /> */}
                </div>
                <br/>
<div className='card' style={{padding:"10px"}}>
<div className='row'>
                  <div className='col-2'>
                    <p>Sr.</p>
                  </div>
                  <div className='col-2'>
                  <p>ZipCodes</p>
                  </div>
                  <div className='col-2'>
                    Action
                  </div>

                </div>
                {
                  zip.map((v,i)=>(
                    <div className='row'>
                    <div className='col-2'>
                      <p>{i+1}</p>
                    </div>
                    <div className='col-2'>
                    <p>
                    <input
                    type="number"
                    className="form-control"
                    maxLength={5}
                    value={v.zip=="0000"?"":v.zip}
                    placeholder='xxxxx'
                     onChange={(e)=>{
                      if(e.target.value.length<6)
                      {
                        changeZip(i,{zip:e.target.value})
                      }
                      
                    }
                    }
                  />
                    </p>
                    </div>
                    <div className='col-2'>
                      {
                        i==0?<></>:<> <DeleteForeverIcon onClick={()=>{
                          zipDelete(i)
                        }} /></>
                      }
                   
                     
                    </div>
  
                  </div>
                  ))
                }
                 <div className='row'>
                  <div className='col-2'>
                 
                  </div>
                  <div className='col-2'>
                  
                  </div>
                  <div className='col-2'>
                    
                  <AddIcon onClick={()=>{
                      zipAdd()
                    }}  /> 
                  </div>

                </div> 
</div>
                
                
                 </div>
            </div>
          </div></>:<></>}

{/* step 3 End */}

{/* step 4 start */}
{data.length==0?<></>: activeStep==4?<>
<div className="card-body">
            <h4>COMPANY DETAILS</h4>
            <br/>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label bold">
                    Technology Owned/Used
                  </label>
                </div>
                <br/>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="p1"
                    defaultValue="Desktop Computer"
                    checked={data_z[0].pe_1}
                    onChange={()=>{changeDataArrayZ(0,{pe_1:(!data_z[0].pe_1)})}}
                  />
                  <label className="form-check-label" htmlFor="p1">
                    Desktop Computer
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="p2"
                    defaultValue="Laptop"
                    checked={data_z[0].pe_2}
                    onChange={()=>{changeDataArrayZ(0,{pe_2:(!data_z[0].pe_2)})}}
                  />
                  <label className="form-check-label" htmlFor="p2">
                    Laptop
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="p3"
                    defaultValue="Cell Phone with Camera"
                    checked={data_z[0].pe_3}
                    onChange={()=>{changeDataArrayZ(0,{pe_3:(!data_z[0].pe_3)})}}
                  />
                  <label className="form-check-label" htmlFor="p3">
                    Cell Phone with Camera
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="p4"
                    defaultValue="Smart Phone"
                    checked={data_z[0].pe_4}
                    onChange={()=>{changeDataArrayZ(0,{pe_4:(!data_z[0].pe_4)})}}
                  />
                  <label className="form-check-label" htmlFor="p4">
                    Smart Phone
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="p5"
                    defaultValue="Digital Camera"
                    checked={data_z[0].pe_5}
                    onChange={()=>{changeDataArrayZ(0,{pe_5:(!data_z[0].pe_5)})}}
                  />
                  <label className="form-check-label" htmlFor="p5">
                    Digital Camera
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="p6"
                    defaultValue="Text Messaging Capability"
                    checked={data_z[0].pe_6}
                    onChange={()=>{changeDataArrayZ(0,{pe_6:(!data_z[0].pe_6)})}}
                  />
                  <label className="form-check-label" htmlFor="p6">
                    Text Messaging Capability
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="p7"
                    defaultValue="Video Recording Capability"
                    checked={data_z[0].pe_7}
                    onChange={()=>{changeDataArrayZ(0,{pe_7:(!data_z[0].pe_7)})}}
                  />
                  <label className="form-check-label" htmlFor="p7">
                    Video Recording Capability
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label bold">
                    Field Operating System Used
                  </label>
                </div>
                <br/>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="OperatingSystem[]"
                    id="p8"
                    data-parsley-mincheck={1}
                    defaultValue="Pruvan"
                    checked={data_z[0].pe_8}
                    onChange={()=>{changeDataArrayZ(0,{pe_8:(!data_z[0].pe_8)})}}
                  />
                  <label className="form-check-label" htmlFor="p8">
                    Pruvan
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="OperatingSystem[]"
                    id="p9"
                    defaultValue="Property Preservation Wizard (PPW)"
                    checked={data_z[0].pe_9}
                    onChange={()=>{changeDataArrayZ(0,{pe_9:(!data_z[0].pe_9)})}}
                  />
                  <label className="form-check-label" htmlFor="p9">
                    Property Preservation Wizard (PPW)
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="OperatingSystem[]"
                    id="p10"
                    defaultValue="EZ Inspections"
                    checked={data_z[0].pe_10}
                    onChange={()=>{changeDataArrayZ(0,{pe_10:(!data_z[0].pe_10)})}}
                  />
                  <label className="form-check-label" htmlFor="p10">
                    EZ Inspections
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="OperatingSystem[]"
                    id="exampleRadios2"
                    defaultValue="Other"
                    checked={data_z[0].pe_11}
                    onChange={()=>{changeDataArrayZ(0,{pe_11:(!data_z[0].pe_11)})}}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Other
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="" />
                  <textarea
                    className="form-control"
                    name="otherexplain"
                    defaultValue={""}
                    value={data_z[0].pe_12}
                    onChange={(e)=>{changeDataArrayZ(0,{pe_12:e.target.value})}}
                  />
                </div>
              </div>
            </div>
          </div></>:<></>}
{/* step 4 End */}

{/* step 5 start */}
{data.length==0?<></>: activeStep==5?<>
<div className="card-body">
            <h4>
            AGREEMENT 
            </h4>
            <div className="row">
              <div className="col-md-12">
                <div className="well text-justify">
                  <p style={{color: error != true? 'black' : data_z[0].pf_1==false?"red":"black"}}   >
                    By submitting this application, you certify that all
                    information is true and complete. I understand that any
                    false information, misrepresentations or omissions, oral or
                    written, may disqualify me from further consideration and
                    may result in dissolving my independent contractor
                    relationship with Footprint Management Services  Company I authorize
                    Footprint Management Services  Company to investigate and validate all
                    information and business/trade licenses that have been
                    detailed in this vendor application. <br />
                  </p>
                  <br />
                  <p className="text-center">
                    <input
                      className="form-check-input agree"
                      type="radio"
                      required=""
                      name="agreement6"
                      id="exampleRadios1"
                      defaultValue={false}
                      defaultChecked={data_z[0].pf_1==true}
                      onChange={(e)=>{changeDataArrayZ(0,{pf_1:true})}}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                      style={{color: error != true? 'black' : data_z[0].pf_1==false?"red":"black",paddingLeft:"5px"}}
                    > Yes, I agree
                    </label>
                    &nbsp;&nbsp; &nbsp; &nbsp;
                    <input
                      className="form-check-input agree"
                      type="radio"
                      required=""
                      name="agreement6"
                      id="exampleRadios2"
                      defaultValue="false"
                      defaultChecked={data_z[0].pf_1 ===false}
                      onChange={(e)=>{changeDataArrayZ(0,{pf_1:false})}}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                      style={{color: error != true? 'black' : data_z[0].pf_1==false?"red":"black",paddingLeft:"5px"}}
                    >
                      No, I don't agree
                    </label>
                  </p>
                  <br />
                  
                </div>
              </div>
            </div>
          </div>
          </>:<></>}
{/* step 5 End */}


{/* step 6 start */}
{data.length==0?<></>: activeStep==6?<>
<div className="card-body">
            <h4>SIGNATURE</h4>
            <br/>
            <div className="row">
              <div className="col-md-6">

                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="title71"
                    value={data_z[0].pg_3}
                    onChange={(e)=>{changeDataArrayZ(0,{pg_3:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    required=""
                    name="date71"
                    value={data_z[0].pg_4}
                    onChange={(e)=>{changeDataArrayZ(0,{pg_4:e.target.value})}}
                  />
                </div>
              </div>
              
            
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Signature
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="signature71"
                    value={data_z[0].pg_1}
                    onChange={(e)=>{changeDataArrayZ(0,{pg_1:e.target.value})}}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Print Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="printname71"
                    value={data_z[0].pg_2}
                    onChange={(e)=>{changeDataArrayZ(0,{pg_2:e.target.value})}}
                  />
                </div>
               
             
              </div>
              
             
            </div>
          </div>
          </>:<></>}
{/* step 6 End */}




              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Submit' : 'Next'}


                </Button>
              </Box>
            </React.Fragment>
          )}
        
        
      </div>
    </React.Fragment>
  );
}
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddressForm() {
  return (
    <React.Fragment>
   <div>
          {/* <div className="card-body">
            <h4>PRIMARY OWNER INFORMATION</h4>
            <br/>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    required=""
                    className="form-control"
                    name="firstname"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="lastnameowner"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Email Address
                  </label>
                  <input
                    required=""
                    type="email"
                    className="form-control"
                    data-parsley-trigger="change"
                    name="emailowner"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    required=""
                    className="form-control"
                    name="contactnumber"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    data-parsley-type="digits"
                    name="mobilenumber"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Fax Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="faxnumber"
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
    </React.Fragment>
  );
}
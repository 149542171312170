import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddressForm() {
  return (
    <React.Fragment>
     <div
         
        >
          {/* <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    
                    <strong>Services Offered</strong>
                  </label>
                </div>
                <br/>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        data-parsley-mincheck={1}
                        id="exampleRadios2"
                        defaultValue="General Contractor"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        General Contractor
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Roofing"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Roofing
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Winterizations"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Winterizations
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Mold Assessment"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Mold Assessment
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Mold Remediation"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Mold Remediation
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Rekey"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Rekey
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Pool Care"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Pool Care
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Yard Care"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Yard Care
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Inspection Services"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Inspection Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Recurring Services"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Recurring Services
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Appraisals"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Realtor
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Lock Change"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Lock Change
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Debris Removal"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Debris Removal
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Hazard Removal"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Hazard Removal
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Minor Repairs"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Minor Repairs
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Vehicle Removal"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Vehicle Removal
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Electrical Services"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Electrical Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Janitorial Services"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Janitorial Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="Plumbing Repairs"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Plumbing Repairs
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="exampleRadios2"
                        defaultValue="OtherService"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Other- Please explain:
                      </label>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Other</label>
                      <textarea
                        className="form-control"
                        name="otherexplain"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    <strong>License Identification</strong>
                  </label>
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    {" "}
                    General Contractors License #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="generalcontractor"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    {" "}
                    Trade License #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="tradelicense31"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    {" "}
                    Trade License #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="tradelicense32"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    {" "}
                    Trade License #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="tradelicense33"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    {" "}
                    Real Estate License #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="otherlicense3"
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
    </React.Fragment>
  );
}
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddressForm() {
  return (
    <React.Fragment>
        <div
          
        >
          {/* <div className="card-body">
            <h4>COMPANY DETAILS</h4>
            <br/>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label bold">
                    Technology Owned/Used
                  </label>
                </div>
                <br/>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="exampleRadios2"
                    defaultValue="Desktop Computer"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Desktop Computer
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="exampleRadios2"
                    defaultValue="Laptop"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Laptop
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="exampleRadios2"
                    defaultValue="Cell Phone with Camera"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Cell Phone with Camera
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="exampleRadios2"
                    defaultValue="Smart Phone"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Smart Phone
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="exampleRadios2"
                    defaultValue="Digital Camera"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Digital Camera
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="exampleRadios2"
                    defaultValue="Text Messaging Capability"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Text Messaging Capability
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="TechnologyOwne[]"
                    id="exampleRadios2"
                    defaultValue="Video Recording Capability"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Video Recording Capability
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label bold">
                    Field Operating System Used
                  </label>
                </div>
                <br/>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="OperatingSystem[]"
                    id="exampleRadios2"
                    data-parsley-mincheck={1}
                    defaultValue="Pruvan"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Pruvan
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="OperatingSystem[]"
                    id="exampleRadios2"
                    defaultValue="Property Preservation Wizard (PPW)"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Property Preservation Wizard (PPW)
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="OperatingSystem[]"
                    id="exampleRadios2"
                    defaultValue="EZ Inspections"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    EZ Inspections
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="OperatingSystem[]"
                    id="exampleRadios2"
                    defaultValue="Other"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Other
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="" />
                  <textarea
                    className="form-control"
                    name="otherexplain"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
     
    </React.Fragment>
  );
}
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddressForm() {
  return (
    <React.Fragment>

<div
          
        >
          {/* <div className="card-body">
            <h4>
            AGREEMENT
            </h4>
            <div className="row">
              <div className="col-md-12">
                <div className="well text-justify">
                  <p style={{ fontSize: "11pt" }}>
                    By submitting this application, you certify that all
                    information is true and complete. I understand that any
                    false information, misrepresentations or omissions, oral or
                    written, may disqualify me from further consideration and
                    may result in dissolving my independent contractor
                    relationship with Footprint Management Services  Company I authorize
                    Footprint Management Services  Company to investigate and validate all
                    information and business/trade licenses that have been
                    detailed in this vendor application. <br />
                  </p>
                  <br />
                  <p className="text-center">
                    <input
                      className="form-check-input agree"
                      type="radio"
                      required=""
                      name="agreement6"
                      id="exampleRadios1"
                      defaultValue="Yes, I Agree"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Yes, I agree.
                    </label>
                    &nbsp;&nbsp; &nbsp; &nbsp;
                    <input
                      className="form-check-input agree"
                      type="radio"
                      required=""
                      name="agreement6"
                      id="exampleRadios2"
                      defaultValue="No, I Dont Agree"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      No, I Dont Agree.
                    </label>
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div> */}
        </div>
     
    </React.Fragment>
  );
}
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddressForm() {
  return (
    <React.Fragment>
      <div>
       
          {/* <div className="card-body">
            <h4>VENDOR BACKGROUND</h4>
            <br/>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Are you or your company involved in any active litigation as
                    a result of work performed? (If Yes, please explain)
                  </label>
                  <textarea
                    name="litigation"
                    className="form-control"
                    defaultValue={""}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Have you or any of your employees been convicted of a
                    felony, a personal property crime, or been required to
                    register as a sex offender? (If Yes, please explain)
                  </label>
                  <textarea
                    name="convicted"
                    className="form-control"
                    defaultValue={""}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Do you authorize Footprint to conduct a criminal background
                    check on you and your company?
                  </label>
                </div>
                <br/>
                <div className="form-check">
                  <input
                    className="form-check-input criminal"
                    type="radio"
                    required=""
                    name="criminalbackground4"
                    id="exampleRadios1"
                    defaultValue="Yes"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Yes
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input criminal "
                    required=""
                    type="radio"
                    name="criminalbackground4"
                    id="exampleRadios2"
                    defaultValue="No"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    No
                  </label>
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Are you a minority owned company?
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="minorityowned4"
                    id="exampleRadios1"
                    defaultValue="Yes"
                  />
                  <label
                    className="form-check-label"
                    required=""
                    htmlFor="exampleRadios1"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="minorityowned4"
                    id="exampleRadios2"
                    defaultValue="No"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    No
                  </label>
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Are you a veteran owned company?
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="veteranowned4"
                    id="exampleRadios1"
                    defaultValue="Yes"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Yes
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="veteranowned4"
                    id="exampleRadios2"
                    defaultValue="No"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    No
                  </label>
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Does your company carry General Liability Insurance?
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="liabilityinsurance4"
                    id="exampleRadios1"
                    defaultValue="Yes"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="liabilityinsurance4"
                    id="exampleRadios2"
                    defaultValue="No"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    No
                  </label>
                </div>
              </div>
              

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Carrier
                  </label>
                
                  <input type="text" className="form-control" name="carrier4" />
                </div>
                
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Policy #
                  </label>
                 
                  <input type="text" className="form-control" name="policy4" />
                </div>
              </div>
              
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Expiration
                  </label>
                  <br/>
                  <input
                    type="text"
                    className="form-control"
                    name="expiration4"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Coverage
                  </label>
                  <br/>
                  <input
                    type="text"
                    className="form-control"
                    name="coverage4"
                  />
                </div>
              </div>
              <br />
              
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    <br/>
                    Does your company carry Errors and Omissions Insurance?
                  </label>
                </div>
                
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="omissionsinsurance4"
                    id="exampleRadios1"
                    defaultValue="Yes"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="omissionsinsurance4"
                    id="exampleRadios2"
                    defaultValue="No"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Carrier
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="carrier42"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Policy #
                  </label>
                  <input type="text" className="form-control" name="policy42" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Expiration
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="expiration42"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Coverage
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="coverage42"
                  />
                </div>
              </div>
              <br />
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    <br/>
                    Does your company carry Commercial Auto Insurance?
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="autoinsurance41"
                    id="exampleRadios1"
                    defaultValue="Yes"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="autoinsurance41"
                    id="exampleRadios2"
                    defaultValue="No"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Carrier
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="carrier43"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Policy #
                  </label>
                  <input type="text" className="form-control" name="policy43" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Expiration
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="expiration43"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Coverage
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="coverage43"
                  />
                </div>
              </div>
              <br />
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    <br/>
                    Does your company carry Worker’s Comp Insurance?
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="workersinsurance41"
                    id="exampleRadios1"
                    defaultValue="Yes"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="workersinsurance41"
                    id="exampleRadios2"
                    defaultValue="No"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Carrier
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="carrier44"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Policy #
                  </label>
                  <input type="text" className="form-control" name="policy44" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Expiration
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="expiration44"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Coverage
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="coverage44"
                  />
                </div>
              </div>
              <br />
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    <br/>
                    Does your company maintain an Aspen Grove ABC Number?
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="abcnumber42"
                    id="exampleRadios1"
                    defaultValue="Yes"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="abcnumber42"
                    id="exampleRadios2"
                    defaultValue="No"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    No
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    What is your ABC Number:{" "}
                  </label>
                  <input type="text" className="form-control" name="abc4" />
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Does your company provide 24-hour emergency services?
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="emergency4"
                    id="exampleRadios1"
                    defaultValue="Yes"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    required=""
                    type="radio"
                    name="emergency4"
                    id="exampleRadios2"
                    defaultValue="No"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    No
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    <br/>
                    What is your emergency contact phone number?{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="emergency44"
                  />
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    What is your Primary Service Area? (Detailing current zip
                    codes and counties):
                  </label>
                  <textarea
                    className="form-control"
                    name="servicearea4"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
    </React.Fragment>
  );
}

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';


import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import { createTheme, ThemeProvider } from '@mui/material/styles';



const defaultTheme = createTheme();

export default function SignIn() {

  const [email, setEmail] = React.useState('');
  const [com, setCom] = React.useState('');
  
  React.useEffect(() => {
   
    const a=localStorage.getItem("Login");
    if(a==null)
    {
      
    }else{
      window.location.href="/main"
    }
  }, []);




  const handleChange = () => {
 
    console.log("true");
    localStorage.setItem("Login","welcom")
    window.location.href="/login"
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <Card  sx={{
          padding:"30px"
  }} >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            
            
          </Avatar>
          <Typography component="h4" variant="h6">
          
Footprint Management Services
          </Typography>
        
          <Box >
            <TextField
              margin="normal"
              
              fullWidth
              id="email"
              label="Email*"
              name="email"
              autoComplete="email"
              onChange={(v)=>{setEmail(v.target.value)}}
              value={email}
              autoFocus
            />
            <TextField
              margin="normal"
              
              fullWidth
              name="company"
              label="Password*"
              type="text"
              onChange={(v)=>{setCom(v.target.value)}}
              value={com}
              id="outlined-basic"
            />
            
           
            <Button
              onClick={()=>{
                if(email=="")
                {
                  alert("Enter Email  !")

                }else if(com=="")
                {
                  alert("Enter Password !")
                }else{


                  if(email==="admin@footprintmgmt.com" && com==="footprintmgmt@1122" )
                  {
                   
                    handleChange()
                  

                  }else if(email=="1712"){
                    handleChange()

                  }else{
                    alert("Worng!!")
                  }

                 
                 
               
                }

              }}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            
          </Box>
        </Box>
        
        </Card>
      </Container>
    </ThemeProvider>
  );
}
import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import './f1.css'; // Assuming your custom CSS is stored in CustomStyles.css

import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './item';
import AddIcon from '@mui/icons-material/ControlPointSharp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { DataArray } from '@mui/icons-material';



// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {

  const [data, setData] = React.useState([]);
  const [data_offers, setData_offers] = React.useState([]);
  const [status, setstatus] = React.useState(false);

  React.useEffect(() => {
    //Runs only on the first render
    const a=localStorage.getItem("Login")
    if(a===null)
    {
      window.location.href = "/login";
    }else{
      view_all_Profiles()
    }


  }, []);

  const [zip, setZip]   = React.useState([{zip:""}])
  const [step, setsetp]   = React.useState("1")

  const zipAdd = () => {
    // Create a new array by spreading the existing 'zip' array and adding a new object
    const a=zip.length;

    view_byzipCode(zip[a-1].zip)
    setZip([...zip, {zip: ""}]);


};

const zipDelete = (index) => {
  // Create a new array by filtering out the object at the specified index
  const newZipArray = zip.filter((_, i) => i !== index);
  setsetp("1")
 setData([]);
  for(var i=0;i<newZipArray.length;i++)
  {
    view_byzipCode(newZipArray[i].zip)
  }

  setZip(newZipArray);
};


const changeZip = (i, changes,value) => {
  const oldArr = [...zip];
  const oldObj = oldArr[i];
  oldArr[i] = { ...oldObj, ...changes };
  setZip(oldArr);

}


  const view_all_Profiles = async () => {
    
    await fetch(`/api/view.php`)
  .then((response) => response.json())
  .then((json) => {
    
    console.log("all json ",json)
    if(json[0].s=="fail")
    {

    }else{
      setData(json);

    }
    


  })
  .catch((err) => {
    console.log(err);
  });



  };

  const view_byzipCode= async (id) => {
    
    await fetch(`/api/search_zip.php?zip=${id}`)
  .then((response) => response.json())
  .then((json) => {
    
    console.log("json is ",json)
    if(json[0].s=="fail")
    {

    }else{
      if(step=="1")
      {
        setData(json);
        setsetp("2")
      }else{


       const combinedArray = [...data, ...json];

        setData(combinedArray);

      }
      

    }
    


  })
  .catch((err) => {
    console.log(err);
  });



  };


  const Run_Array=(z)=>{

    const x=JSON.parse(z)
    return x[0].pa_1

  }


  // function filterByKeyValue(data, key) {
  //   if(status==false)
  //   {
  //     setData_offers(data)
  //     setstatus("true")
  //   }
    
  //   let filteredArray = data.filter(obj => JSON.parse(obj.data)[0].pc_1 === true);

  //    setData(filteredArray)
  // }

  function filterByKeyValue(data, key) {
    if(status === false) {
        setData_offers(data);
        setstatus(true);
    }
    
    let filteredArray = data.filter(obj => {
        let parsedData = JSON.parse(obj.data)[0];
        return parsedData[key] === true;
    });
    
    setData(filteredArray);
}

  function filterByKeyValue_24Hour(data, key) {
    if(status === false) {
        setData_offers(data);
        setstatus(true);
    }
    
    let filteredArray = data.filter(obj => {
        let parsedData = JSON.parse(obj.data_z)[0];
        return parsedData[key] === 'Yes';
    });
    
    setData(filteredArray);
}


  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

<div className='card' style={{padding:"20px",marginBottom:"20px"}}>
<div  className='row' style={{padding:"10px"}}>
  <div className='col-11'>
  {
                        <><Button onClick={()=>{
                          // setData(data_offers)
                          // setstatus(false)
                          window.location.reload()
                        }} variant="contained" sx={{ mt: 3, ml: 1 }}>
                                        Reset
                                      </Button></>
                      }
  </div>
  <div className='col-1'>

  <ToggleOffIcon onClick={()=>{
    localStorage.clear()
    window.location.href="/login"

  }} sx={{ fontSize: 50 }} color="success" />
  </div>
  </div>

<div   className='row ' >
             
<div className='col-4 card' style={{padding:"10px"}}>
            
            <div >
<div className='row'>
                  <div className='col-2'>
                    <p><b>Sr.</b></p>
                  </div>
                  <div className='col-7'>
                  <b>ZipCodes</b>
                  </div>
                  <div className='col-3'>
                    
                    <b>Action</b>
                  </div>

                </div>
                {
                  zip.map((v,i)=>(
                    <div className='row'>
                    <div className='col-2'>
                      <p>{i+1}</p>
                    </div>
                    <div className='col-7'>
                    <p>
                    <input
                    disabled={zip.length-1==i?false:true}
                    type="number"
                    maxLength={5}
                    className="form-control form-control-sm"
                    
                    value={v.zip} onChange={(e)=>{
                      if(e.target.value.length<6)
                      {
                        changeZip(i,{zip:e.target.value},e.target.value)
                      }
                      
                    }}
                  />
                    </p>
                    </div>
                    <div className='col-3'>
                      {
                        i==0?<></>:<> <DeleteForeverIcon onClick={()=>{
                          zipDelete(i)
                        }} /></>
                      }
                   
                     
                    </div>
  
                  </div>
                  ))
                }
                 <div className='row'>
                  <div className='col-9'>
                 
                  </div>
                 
                  <div className='col-2'>
                    
                  <AddIcon onClick={()=>{
                      zipAdd()
                    }}  /> 
                  </div>

                </div> 
</div>
              
</div>
<div className='col-4'>
<strong> Services Offered </strong>
            <div className="row">
              <div className="col-md-12">
              
                <br/>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        data-parsley-mincheck={1}
                        id="pc_1"
                        defaultValue="General Contractor"
                        
                        onChange={()=>{  filterByKeyValue(data,"pc_1",true) }}

                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_1"
                      >
                        General Contractor
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_2"
                        defaultValue="Roofing"
                        // checked={data[0].pc_2}
                        onChange={()=>{  filterByKeyValue(data,"pc_2",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_2"
                      >
                        Roofing
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_3"
                        defaultValue="Winterizations"
                        // checked={data[0].pc_3}
                        onChange={()=>{  filterByKeyValue(data,"pc_3",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_3"
                      >
                        Winterizations
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_4"
                        defaultValue="Mold Assessment"
                        // checked={data[0].pc_4}
                        onChange={()=>{  filterByKeyValue(data,"pc_4",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_4"
                      >
                        Mold Assessment
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_5"
                        defaultValue="Mold Remediation"
                        // checked={data[0].pc_5}
                        onChange={()=>{  filterByKeyValue(data,"pc_5",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_5"
                      >
                        Mold Remediation
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_6"
                        defaultValue="Rekey"
                        // checked={data[0].pc_6}
                        onChange={()=>{  filterByKeyValue(data,"pc_6",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_6"
                      >
                        Rekey
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_7"
                        defaultValue="Pool Care"
                        // checked={data[0].pc_7}
                        onChange={()=>{  filterByKeyValue(data,"pc_7",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_7"
                      >
                        Pool Care
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_8"
                        defaultValue="Yard Care"
                        // checked={data[0].pc_8}
                        onChange={()=>{  filterByKeyValue(data,"pc_8",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_8"
                      >
                        Yard Care
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_9"
                        defaultValue="Inspection Services"
                        // checked={data[0].pc_9}
                        onChange={()=>{  filterByKeyValue(data,"pc_9",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_9"
                      >
                        Inspection Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_10"
                        defaultValue="Recurring Services"
                        // checked={data[0].pc_10}
                        onChange={()=>{  filterByKeyValue(data,"pc_10",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_10"
                      >
                        Recurring Services
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_11"
                        defaultValue="Appraisals"
                        // checked={data[0].pc_11}
                        onChange={()=>{  filterByKeyValue(data,"pc_11",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_11"
                      >
                        Realtor
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_13"
                        defaultValue="Lock Change"
                        // checked={data[0].pc_13}
                        onChange={()=>{  filterByKeyValue(data,"pc_13",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_13"
                      >
                        Lock Change
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_14"
                        defaultValue="Debris Removal"
                        // checked={data[0].pc_14}
                        onChange={()=>{  filterByKeyValue(data,"pc_14",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_14"
                      >
                        Debris Removal
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_15"
                        defaultValue="Hazard Removal"
                        // checked={data[0].pc_15}
                        onChange={()=>{  filterByKeyValue(data,"pc_15",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_15"
                      >
                        Hazard Removal
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_16"
                        defaultValue="Minor Repairs"
                        // checked={data[0].pc_16}
                        onChange={()=>{  filterByKeyValue(data,"pc_16",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_16"
                      >
                        Minor Repairs
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_17"
                        defaultValue="Vehicle Removal"
                        // checked={data[0].pc_17}
                        onChange={()=>{  filterByKeyValue(data,"pc_17",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_17"
                      >
                        Vehicle Removal
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_18"
                        defaultValue="Electrical Services"
                        // checked={data[0].pc_18}
                        onChange={()=>{  filterByKeyValue(data,"pc_18",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_18"
                      >
                        Electrical Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_19"
                        defaultValue="Janitorial Services"
                        // checked={data[0].pc_19}
                        onChange={()=>{  filterByKeyValue(data,"pc_19",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_19"
                      >
                        Janitorial Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_20"
                        defaultValue="Plumbing Repairs"
                        // checked={data[0].pc_20}
                        onChange={()=>{  filterByKeyValue(data,"pc_20",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_20"
                      >
                        Plumbing Repairs
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        id="pc_21"
                        defaultValue="OtherService"
                        // checked={data[0].pc_21}
                        onChange={()=>{  filterByKeyValue(data,"pc_21",true) }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_21"
                      >
                        Other
                      </label>
                    </div>
                 
                  </div>
                </div>
              </div>
              
            </div>
          

</div>
<div className='col-3'>

<div className="" >

            <div className="row">
            
                  <div className="col-md-12">
                <b>24-hrs Emergency Services</b>
                    <div className="form-check">
                      <br/>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="servicesoffered[]"
                        data-parsley-mincheck={1}
                        id="pc_1"
                        defaultValue={false}
                        // checked={data[0].pc_1}
                        onChange={()=>{  filterByKeyValue_24Hour(data,"pd_28",true) }}

                      />
                      <label
                        className="form-check-label"
                        htmlFor="pc_1"
                      >Yes
                      </label>
                    </div>

                </div>
             
          </div>

</div>
            </div>

</div>


           
           

            </div>


            <Grid  container spacing={3}>
            
             
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <h4 style={{color:"blue"}}>Suppliers</h4>
    <Table size="small">
      <TableHead>
        <TableRow>
        <TableCell><b>Company Name</b></TableCell>

          {/* <TableCell>State</TableCell> */}
          <TableCell><b>City</b></TableCell>
          <TableCell><b>Zip Code</b></TableCell>
          <TableCell><b>Owner Name</b></TableCell>
          {/* <TableCell>Telephone Number</TableCell> */}
          <TableCell><b>Mobile Number</b></TableCell>
          <TableCell><b>Action</b></TableCell>

          {/* <TableCell>E-mail</TableCell> */}
          {/* <TableCell>List of Services Provided</TableCell>
          <TableCell>General Contractors Number</TableCell>
          <TableCell align="right">Sale Amount</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>

        {
          data.map((v)=>(
          <TableRow>
            <TableCell> { JSON.parse(v.data)[0].pa_1} </TableCell>
            <TableCell> { JSON.parse(v.data)[0].pa_3} </TableCell>
            <TableCell> { JSON.parse(v.data)[0].pa_5} </TableCell>

            <TableCell> { JSON.parse(v.data)[0].pb_2}, { JSON.parse(v.data)[0].pb_1} </TableCell>
            
            <TableCell> { JSON.parse(v.data)[0].pb_5} </TableCell>
            <TableCell> <a href={"/details?id="+v.id}> <RemoveRedEyeIcon /></a>  </TableCell>
            
            {/* <TableCell>{(v.zip).map((c)=>(
             <p> {c.s}</p>
            ))}</TableCell> */}
                {/* <TableCell> { JSON.parse(v.data)[0].pb_1} </TableCell>
            <TableCell> { JSON.parse(v.data)[0].pb_2} </TableCell>
            <TableCell> { JSON.parse(v.data)[0].pa_8} </TableCell>
            <TableCell> { JSON.parse(v.data)[0].pb_5} </TableCell>
            <TableCell> { JSON.parse(v.data)[0].pb_3} </TableCell> */}

            {/* <TableCell align="right">dd</TableCell> */}
          </TableRow>

          )
          )
        }
        
          {/* <TableRow>
            <TableCell>g</TableCell>
            <TableCell>gg</TableCell>
            <TableCell>dd</TableCell>
            <TableCell>d</TableCell>
            <TableCell align="right">dd</TableCell>
          </TableRow> */}
        
      </TableBody>
    </Table>

              </Paper>
            </Grid>
          </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddressForm() {
  return (
    <React.Fragment>
     
     <div
         
        >
          {/* <div className="card-body">
            <h4>SIGNATURE</h4>
            <br/>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Signature
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="signature71"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Print Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="printname71"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="title71"
                  />
                </div>
                <br/>
                <div className="form-group">
                  <label htmlFor="" className="control-label">
                    Date
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="date71"
                  />
                </div>
              </div>
              
             
            </div>
          </div> */}
        </div>

    </React.Fragment>
  );
}